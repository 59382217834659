import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import Footer from "../../components/Footer";
import Hero from "../../components/Hero";
import CorporateStatement from "../../components/CorporateStatement";
import WhatsappChat from "../../components/WhatsappChat";
import business from "../../images/business.svg";
import { localStorageKeys } from "../../utility/localstorage";
import { getTranslatedText } from "../../utility/constant";

const CorporateStatementPageIndex = () => {
  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <Wrapper
      myCustomAppState={myCustomAppState}
      setMyCustomAppState={setMyCustomAppState}
    >
      <Hero
        myCustomAppState={myCustomAppState}
        setMyCustomAppState={setMyCustomAppState}
        headlineOne={getTranslatedText(
          myCustomAppState.language,
          "Corporate BannerHeading1"
        )}
        headlineThree={getTranslatedText(
          myCustomAppState.language,
          "Corporate BannerHeading2"
        )}
        buttonLabel={getTranslatedText(
          myCustomAppState.language,
          "Banner DownloadButton"
        )}
        rightColImage={business}
      />
      <CorporateStatement
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <Footer
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <WhatsappChat />
    </Wrapper>
  );
};

export default CorporateStatementPageIndex;
