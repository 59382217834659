import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Line from "./Line";

const AboutUs = ({ getTranslatedText }) => {
  const tableData = [
    {
      name: getTranslatedText("Corporate Name"),
      // descp: `${getTranslatedText("Corporate NameTitle Bold")} ${getTranslatedText("Corporate NameTitle")}`,
      descp : <p> <b>{getTranslatedText("Corporate NameTitle Bold")}</b> {getTranslatedText("Corporate NameTitle")}</p>
    },
    {
      name: getTranslatedText("Corporate Reg"),
      descp: "147115",
    },
    {
      name: getTranslatedText("Corporate Tax"),
      descp: "6942164-5",
    },
    {
      name: getTranslatedText("Corporate Status"),
      descp: getTranslatedText("Corporate StatusTitle"),
    },
    {
      name: getTranslatedText("Corporate Address"),
      descp: getTranslatedText("Corporate AddressTitle"),
    },
    {
      name: getTranslatedText("Corporate Fax"),
      descp: "Fax: (92 21) 34386451",
    },
    {
      name: getTranslatedText("Corporate Email"),
      descp: "support@creditper.pk",
    },
    // {
    //   name: getTranslatedText("Corporate Launch"),
    //   descp: "",
    // },
    {
      name: getTranslatedText("Corporate Officer"),
      descp: getTranslatedText("Corporate OfficerTitle"),
    },
    {
      name: getTranslatedText("Corporate Auditor"),
      descp: "KPMG",
    },
    {
      name: getTranslatedText("Corporate Advisor"),
      descp: getTranslatedText("Corporate AdvisorTitle"),
    },
    {
      name: getTranslatedText("Corporate Regulator"),
      descp: getTranslatedText("Corporate RegulatorTitle"),
    },
    // {
    //   name: getTranslatedText("Corporate Shares"),
    //   descp: "",
    // },
    // {
    //   name: getTranslatedText("Corporate Certificate"),
    //   descp: "",
    // },
    // {
    //   name: getTranslatedText("Corporate Branch"),
    //   descp: "",
    // },
    // {
    //   name: getTranslatedText("Corporate Associated"),
    //   descp: "",
    // },
    // {
    //   name: getTranslatedText("Corporate Membership"),
    //   descp: "",
    // },
    // {
    //   name: getTranslatedText("Corporate Ethics"),
    //   descp: "",
    // },
  ];

  return (
    <section className="corporate-info" >
      <Container>
        <Row d-flex>
          <Col md={12} lg={12} xl={12}>
            <Line type="large" />
            <h2 className="about__heading">
              {getTranslatedText("Corporate Header")}
            </h2>
            <br />

            <table class="table table-bordered">
              <tbody>
                {tableData.map((data) => (
                  <tr>
                    <td>{data.name}</td>
                    <td>{data.descp}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
